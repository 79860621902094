/*
Notes
=====
There are four special combinators provided by CSS:
1. General sibling selector (~)
2. Descendant selector (space)
3. Child selector (>)
4. Adjacent sibling selector (+)

See https: //www.w3schools.in/css3/descendant-combinator-selector for examples.
*/

// Angular Material CDK Imports and Tweaks
@import "~@angular/cdk/overlay-prebuilt.css";
//@import "../node_modules/@angular/cdk/overlay-prebuilt.css";
.cdk-overlay-container {
  z-index: 2000;
}

/* bootstrap tweaks */
/* tabs need a little whitespace between the tab and content */
.tab-pane {
  margin-top: 10px;
}
.tab-pane-tight {
  // margin-top: 10px;
}

/* bootstrap 4 dropped xs button we want back */
.btn-group-xs > .btn,
.btn-xs {
  padding: 0.2rem 0.2rem;
  font-size: 0.75rem;
  line-height: 0.4;
  border-radius: 0.2rem;
}
.btn-group-xxs > .btn,
.btn-xxs {
  padding: 0.1rem 0.1rem;
  font-size: 0.65rem;
  line-height: 0.35;
  border-radius: 0.2rem;
}

/* Angular 16 update we lost the rounded edge on the last button in our button groups, like our filter-selection
 * button and our 'Add Customer' button.*/
.btn-group>.btn-group:is(:last-child)>.btn {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

/* bootstrap pills */
.nav-pills-tight .nav-link {
  padding: .25rem .5rem;
}
.nav-pills-round .nav-link {
  border-radius: 1rem;
}

/* bootstrap 5 introduced hover underline for nav links that we're not a fan of */
a:hover.nav-link {
  text-decoration: none;
}

/*bootstrap lets control prefix/suffix grow beyond the control height which looks jacked up*/
.input-group-text {
  max-height: calc(1.5em + 0.75rem + 2px);
}

/*bootstrap primary disabled is used for our save button which we want even more disabled looking*/
.btn-save.disabled,
.btn-save:disabled {
  background-color: #007bff9e;
  border-color: #007bff9e;
}

/*ng bootstrap scroll large typeahead results*/
ngb-typeahead-window.dropdown-menu {
  max-height: 500px !important;
  overflow-y: auto;
}

/*ng bootstrap scroll large dropdown menu*/
.ib-dropdown-scroll /*.dropdown-menu*/ {
  /*max-height: calc(100vh - 50px);*/
  max-height: 200px;
  overflow-y: auto;
}

.ib-dropdown-scroll-large /*.dropdown-menu*/ {
  /*max-height: calc(100vh - 50px);*/
  max-height: 500px;
  overflow-y: auto;
}

.card-header-tight {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.card-body-tight {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

/*modal*/
.modal-larger {
  width: 75%;
  max-width: unset;
}
.modal-largest {
  width: 90%;
  max-width: unset;
}

.modal-body .container-fluid {
  /* remove double padding these two classes give together */
  padding-right: 0;
  padding-left: 0;
}

/* code tweaks */
pre {
  margin-bottom: 0;
}

/* bootstrap column narrow gutter helper */
.narrow-gutter {
  padding-left: 5px;
  padding-right: 5px;
}

/* bootstrap card helper */
.card-footer-small {
  padding: 0.25rem 0.75rem;
}

/* bootstrap popover helpers */
.popover-wide {
  width: 400px;
  max-width: 400px;
}
.popover-wider {
  width: 50%;
  max-width: 50%;
}
.popover-widest {
  width: 80%;
  max-width: 80%;
}

/* bootstrap clear button */
.btn-clear {
  color: #212529;
  background-color: inherit;
  border-color: #f8f9fa;
}
.btn-clear:hover {
  color: #212529;
  background-color: inherit;
  border-color: #f8f9fa;
}
.btn-invisible {
  color: #212529;
  background-color: inherit;
  border-color: inherit;
  border-width: 0;
}
.btn-invisible:hover {
  color: #212529;
  background-color: inherit;
  border-color: inherit;
  border-width: 0;
}
/* bootstrap default button */
.btn-default {
  color: #333;
  background-color: #eee;
  /*background-color: #e6e6e6;*/
  /*background-color: #fff;*/
  border-color: #ccc;
}
.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default.active {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

/* stronger hover background color for bootstrap dropdown */
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #cecece75; /*#f8f9fa;*/
}

/* font awesome helpers */
/* note 'p' = 'point' so 1p5 is 1.5 since can't have period in class name */
.fa-0p25x {
  font-size: 0.25em;
}

.fa-0p4x {
  font-size: 0.4em;
}

.fa-0p5x {
  font-size: 0.5em;
}

.fa-1p5x {
  font-size: 1.5em;
}

.fa-2p5x {
  font-size: 2.5em;
}

.fa-3p5x {
  font-size: 3.5em;
}

.fa-4p5x {
  font-size: 4.5em;
}

.fa-5p5x {
  font-size: 5.5em;
}

.fa-6p5x {
  font-size: 6.5em;
}

.fa-7p5x {
  font-size: 7.5em;
}

.fa-8p5x {
  font-size: 8.5em;
}

.fa-9p5x {
  font-size: 9.5em;
}

.fa-15x {
  font-size: 15em;
}

.fa-20x {
  font-size: 20em;
}

.fa-25x {
  font-size: 25em;
}

.fa-30x {
  font-size: 30em;
}

/* prime ng tweaks */
/* when multiselect has custom header the close button is too far down */
/*.p-multiselect-close {
  margin-top: 0.5em !important;
  top: 0.5em !important;
}
*/


/* primeng dropdown group labels are disabled so they can't be selected but that makes them fainter
text when we want them to actually be more prominent to match typical html select groupings */
.primeng-select-item-group {
  font-weight: bolder !important;
  opacity: 1 !important;
  margin-left: -10px !important;
}

/* After updating to Angular 16, it was applying margin-bottom 1rem, padding-left 2rem, and text underline to our
 * context menu on all of our tables.*/
.p-contextmenu dl,
.p-contextmenu ol,
.p-contextmenu ul {
  margin-top: 0;
  margin-bottom: 0;
}
.p-contextmenu ol,
.p-contextmenu ul {
  padding-left: 0;
}
.p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
  text-decoration: none;
}

/* After updating to Angular 16, it was applying margin-bottom 1rem, padding-left 2rem, and text underline to our
 * p-menu on all of our tables. (Menu for clicking hamburger icon on right most column)*/
.p-menu dl,
.p-menu ol,
.p-menu ul {
  margin-top: 0;
  margin-bottom: 0;
}
.p-menu ol,
.p-menu ul {
  padding-left: 0;
}
/* After updating to Angular 16, it was adding a gray background-color to the first menu item, even when it
 * wasn't hovered. Plus it was darker then the hover style.*/
.p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link {
  text-decoration: none;
  background-color: white;
}
.p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link:hover {
  text-decoration: none;
  background-color: rgb(233,236,239);
}

/*
 * After updating to Angular 16, it was applying margin-bottom 1rem and padding-left 2rem to our
 * p-panelmenu-panel on our main side nav bar.
 */
.p-panelmenu-panel dl,
.p-panelmenu-panel ol,
.p-panelmenu-panel ul {
  margin-top: 0;
  margin-bottom: 0;
}
.p-panelmenu-panel ol,
.p-panelmenu-panel ul {
  padding-left: 0;
}

/* sometimes we need to dynamically decide if we want a dataview header or not */
.p-dataview-header-hidden > .p-dataview-header {
  display: none;
}

/* in some scenarios we want a tighter layout for dataview */
.p-dataview-tight > .p-dataview-header {
  padding: 0.25rem 1rem;
}
.p-dataview-tight .call-out-box {
  margin: 0;
  padding: 5px 15px;
}
.p-dataview-tight .p-paginator {
  padding: 0.25rem 0.75rem;
}

/* using disabled for group headers in multiselect so we don't want them dimmed */
.p-multiselect-item.p-corner-all.p-state-disabled {
  opacity: 1 !important;
}

/* multiselect label has bottom margin too large we want this to look like other select boxes */
.p-multiselect-label {
  margin-bottom: 0.2rem;
  padding: 0.375rem 0.75rem !important;
  padding-right: 2rem !important;
}

.p-multiselect-label-wrap {
  white-space: normal !important;
}

.p-multiselect {
  border: 1px solid #ced4da !important;
}

.p-multiselect-small {
  font-size: 0.85rem;
  line-height: 0.85rem;
}

.p-corner-all {
  border-radius: 0.25rem !important;
}

/* prime ng drop down items too much padding */
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.25rem 0.5rem 0.25rem 1.5rem;
}
.p-dropdown-panel .p-dropdown-header {
  padding: 0.75rem;
}

/* prime ng tree too much padding */
.p-tree {
  padding: 0.5rem 1rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  padding: 0.25rem;
}

/*
  prime ng select multi-rows in table via row or checkbox
  See https://github.com/primefaces/primeng/issues/5179 for "simple CSS solution that worked"
*/
.p-table-tbody .p-chkbox {
  pointer-events: none;
}

/* prime ng galleria */
.p-panel-images {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.p-galleria-caption {
  width: 100% !important;
  bottom: 0 !important;
  padding: 0.2em 1em 0.2em 2em !important;
}
.p-galleria-nav-next,
.p-galleria-nav-prev {
  color: black !important;
}

/*
 * Started using p-dock after Angular 16 was in place, but it was applying a 2rem padding just like it was
 * for p-panelmenu-panel and p-context-menu. So we need to override that. Same with margin-bottom.
 */
.p-dock ol, .p-dock ul {
  padding-left: 0rem;
}
.p-dock dl, .p-dock ol, .p-dock ul {
  margin-top: 0;
  margin-bottom: 0rem;
}

/*doka image editor*/
.doka--root {
  --doka-effect--invert: 100%;
  --doka-color--alpha: #2990ff;
  --doka-color--alpha-dim: #1a80ec;
}

/*update stop theme.css*/
/*body .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon:before {
  content: "";
}*/
/*body .p-multiselect .p-multiselect-trigger .p-multiselect-trigger-icon:before {
  content: "";
}*/
/*gridster*/
gridster {
  background: inherit !important;
}
gridster .gridster-column {
  /*border-left: 1px solid yellow;*/
  /*border-right: 1px solid red;*/
  outline: 1px solid yellow;
}

gridster .gridster-row {
  /*border-top: 1px solid yellow;*/
  /*border-bottom: 1px solid red;*/
  outline: 1px solid yellow;
}

/* avatar tweaks */
.inline-avatar .avatar-container {
  /* for when we want to be inline like inside drop down menu */
  display: inline-block !important;
}

/* archwizard tweaks */
/* the following three rules allow the wizard navigation bar to wrap for mobile widths */
aw-wizard-navigation-bar.horizontal ul.steps-indicator {
  flex-wrap: wrap;
}
aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-5 li {
  min-width: 100px;
  margin-bottom: 60px;
}
aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-6 li {
  min-width: 100px;
  margin-bottom: 60px;
}
aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-7 li {
  min-width: 100px;
  margin-bottom: 60px;
}
aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-8 li {
  min-width: 100px;
  margin-bottom: 60px;
}
aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-9 li {
  min-width: 100px;
  margin-bottom: 60px;
}
aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-10 li {
  min-width: 100px;
  margin-bottom: 60px;
}
aw-wizard-step,
aw-wizard-completion-step {
  margin-top: -60px;
}

/* Recaptchav3 badge position. This made it look good in preview mode, but bad on the actual sign up wizard since the wizard has no footer. 
   Keeping it here for reference so we know how to tweak it. */
// .grecaptcha-badge {
  /* Make sure it sits above the footer.*/
  // bottom: 30px !important;
// }