/* loading */
.loading-container {
  position: relative;
  min-height: 50px;
}

.loading-template {
  top: 0;
  z-index: 2002;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #00000080; /*#000000ba;*/
  border-radius: 3px;
}

.loading-template h1,
.loading-template h2,
.loading-template h3,
.loading-template h4,
.loading-template h5,
.loading-template h6,
.loading-template p {
  color: white;
}

/* show something can be clicked with pointer cursor*/
.clickable {
  cursor: pointer;
}

/* show something can be moved with pointer cursor*/
.movable {
  cursor: move;
}

.text-black {
  color: #000;
}


/* Bootstrap 5 links are underlined by default (not just on hover)*/
a:link {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:hover.text-black {
  color: #000;
}

.underline-on-hover:hover {
  text-decoration: underline;
}

.no-underline-on-hover:hover {
  text-decoration: none;
}

.highlight-hover-grey:hover {
  background-color: #f5f5f5 !important;
}

.align-right {
  text-align: right;
}

/* print mode changes */
.print-only {
  display: none;
}

@media print {
  .invoice-print {
    font-size: 10pt !important;
    line-height: 1.5 !important;
  }
  .invoice-print tr {
    page-break-inside: avoid !important;
  }

  /* Web browsers don't print background colors by default, and it can't be overridden using HTML, CSS, or JS. The user has
   * to enable background colors in print settings. Since most users probably have this setting disabled, this turns the text
   * green instead. But if they check the box to print background colors, and now the green badge shows, you can't see the text,
   * so we made the text an off white so it still shows up.
   */
  .badge-print-success {
    color: #28a745 !important;
    background-color: rgb(219, 218, 218) !important;
  }
  .badge-print-danger {
    color: #dc3545 !important;
    background-color: rgb(219, 218, 218) !important;
  }


  .tab-content > .tab-pane {
    display: block !important;
    opacity: 1 !important;
    visibility: visible !important;
    border-bottom-style: solid;
    border-bottom-color: #212529;
    border-bottom-width: 1px;
    padding-top: 10px;
  }

  .ib-nav-top {
    display: none !important;
  }

  .ib-nav-bottom {
    display: none !important;
  }

  .ib-sidebar-container {
    display: none !important;
  }

  .ib-site-container {
    margin-left: 5px !important;
  }

  .nav-tabs {
    display: none !important;
  }

  .print-only {
    display: inherit !important;
  }

  button {
    display: none !important;
  }
}

/*callouts*/

.call-out-box {
  padding: 15px;
  margin: 15px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
}

.call-out-box h4 {
  margin-top: 0;
  margin-bottom: 5px;
}

.call-out-box p:last-child {
  margin-bottom: 0;
}

.call-out-box code {
  border-radius: 3px;
}

.call-out-box + .call-out-box {
  margin-top: -5px;
}

.call-out-box-danger {
  border-left-color: #ce4844;
}

.call-out-box-danger h4 {
  color: #ce4844;
}

.call-out-box-warning {
  border-left-color: #aa6708;
}

.call-out-box-warning h4 {
  color: #aa6708;
}

.call-out-box-info {
  border-left-color: #1b809e;
}

.call-out-box-info h4 {
  color: #1b809e;
}

/*boardered box*/

.boardered-box {
  padding: 10px;
  margin: 20px 0;
  border: 5px solid #eee;
  border-radius: 3px;
}

.boardered-box h4 {
  margin-top: 0;
  margin-bottom: 5px;
}

.boardered-box p:last-child {
  margin-bottom: 0;
}

.boardered-box code {
  border-radius: 3px;
}

.boardered-box + .boardered-box {
  margin-top: -5px;
}

.boardered-box-danger {
  border-color: #ce4844;
}

.boardered-box-danger h4 {
  color: #ce4844;
}

.boardered-box-warning {
  border-color: #aa6708;
}

.boardered-box-warning h4 {
  color: #aa6708;
}

.boardered-box-info {
  border-color: #1b809e;
}

.boardered-box-info h4 {
  color: #1b809e;
}

/*
   forced z-index when we need to override vendor set z-index
   sometimes primeng and bootstrap disagree about who should be at the top layer and we have to help
*/
.z500 {
  z-index: 500 !important;
}
.z1000 {
  z-index: 1000 !important;
}
.z2000 {
  z-index: 2000 !important;
}
.z3000 {
  z-index: 3000 !important;
}


/* text centered in hr-like line */
.center-separator {
  display: flex;
  line-height: 1em;
  color: gray;
}
.center-separator::before,
.center-separator::after {
  content: "";
  display: inline-block;
  flex-grow: 1;
  margin-top: 0.5em;
  background: gray;
  height: 1px;
  margin-right: 10px;
  margin-left: 10px;
}

/*when macro text gets replaced we have option to show what text originates with a macro*/
.macro-replacement-value {
  background-color: yellow;
  color: black;
}
.ui-state-highlight .macro-replacement-value {
  background-color: yellow;
  color: black;
}
